/**
 * Original file from app_deborla cartridge
 * Modifications:
 * - Fix eslint issues.
 * - Add documentations.
 * - Add categoryID to productGtmData.
 */

'use strict';

var gtmjs = {
    init: function () {
        this.initCustomEvents();
    },

    initCustomEvents: function () {
        var self = this;

        document.addEventListener(
            'GTM-push-addToCart',
            function (e) {
                self.pushAddToCart(e.detail.element, e.detail.quantity);
            },
            false
        );

        document.addEventListener(
            'GTM-push-removeFromCart',
            function (e) {
                self.pushRemoveFromCart(e.detail.element, e.detail.quantity);
            },
            false
        );

        document.addEventListener(
            'GTM-push-productDetail',
            function (e) {
                self.pushProductDetail(e.detail.element);
            },
            false
        );

        document.addEventListener(
            'GTM-push-productImpressios',
            function (e) {
                self.pushProductImpressions(e.detail.elements, e.detail.list);
            },
            false
        );

        document.addEventListener(
            'GTM-push-checkout',
            function (e) {
                self.pushCheckout(e.detail.step, e.detail.productsElements);
            },
            false
        );

        document.addEventListener(
            'GTM-push-purchase',
            function (e) {
                self.pushPurchase(e.detail.productsElements);
            },
            false
        );
    },

    /**
     * Pushes the add to cart event to the dataLayer.
     * @param {Object} element - The element that contains the data attributes
     * @param {string} quantity - The quantity of the product
     */
    pushAddToCart: function (element, quantity) {
        var gtmData = element;

        if (!gtmData) {
            return;
        }

        var name = gtmData.getAttribute('data-name') || 'Name not found';
        var productId = gtmData.getAttribute('data-id') || 'Id not found';
        var price = gtmData.getAttribute('data-price') || '-.--';
        var productQuantity = quantity || '1';
        var category = gtmData.getAttribute('data-category') || 'Category not found';
        var productGtmData = {
            name: String(name),
            id: String(productId),
            price: String(price),
            quantity: String(productQuantity),
            category: String(category)
        };

        window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: [productGtmData]
                }
            }
        });
    },

    /**
     * Pushes the remove from cart event to the dataLayer.
     * @param {Object} element - The element that contains the data attributes
     * @param {string} quantity - The quantity of the product
     */
    pushRemoveFromCart: function (element, quantity) {
        var gtmData = element;

        if (!gtmData) {
            return;
        }

        var name = gtmData.getAttribute('data-name') || 'Name not found';
        var productId = gtmData.getAttribute('data-id') || 'Id not found';
        var price = gtmData.getAttribute('data-price') || '-.--';
        var productQuantity = quantity || '1';
        var category = gtmData.getAttribute('data-category') || 'Category not found';
        var productGtmData = {
            name: String(name),
            id: String(productId),
            price: String(price),
            quantity: String(productQuantity),
            category: String(category)
        };

        window.dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [productGtmData]
                }
            }
        });
    },

    /**
     * Pushes the product detail event to the dataLayer.
     * @param {Object} element - The element that contains the data attributes
     */
    pushProductDetail: function (element) {
        var gtmData = element;

        if (!gtmData) {
            return;
        }

        var name = gtmData.getAttribute('data-name') || 'Name not found';
        var productId = gtmData.getAttribute('data-id') || 'Id not found';
        var price = gtmData.getAttribute('data-price') || '-.--';
        var category = gtmData.getAttribute('data-category') || 'Category not found';
        var productGtmData = {
            name: String(name),
            id: String(productId),
            price: String(price),
            category: String(category)
        };

        window.dataLayer.push({
            event: 'productDetail',
            ecommerce: {
                detail: {
                    products: [productGtmData]
                }
            }
        });
    },

    /**
     * Pushes the product impression event to the dataLayer.
     * @param {Object} elements - The element that contains the data attributes
     * @param {string} list - The list of products
     */
    pushProductImpressions: function (elements, list) {
        var gtmDataArray = elements;

        if (!gtmDataArray) {
            return;
        }

        var productList = [];

        gtmDataArray.forEach((gtmData) => {
            var name = gtmData.getAttribute('data-name') || 'Name not found';
            var productId = gtmData.getAttribute('data-id') || 'Id not found';
            var price = gtmData.getAttribute('data-price') || '-.--';
            var category = gtmData.getAttribute('data-category') || 'Category not found';
            var productGtmData = {
                name: String(name),
                id: String(productId),
                price: String(price),
                list: String(list),
                category: String(category)
            };

            productList.push(productGtmData);
        });

        window.dataLayer.push({
            event: 'productImpressions',
            ecommerce: {
                impressions: productList
            }
        });
    },

    /**
     * Pushes the checkout event to the dataLayer.
     * @param {Object} step - The checkout step
     * @param {Object} productsElements - The elements that contains the product data attributes
     */
    pushCheckout: function (step, productsElements) {
        var stepNumber = Number(step);
        var gtmDataArray = productsElements;

        if (!gtmDataArray) {
            return;
        }

        var dataProducts = [];

        gtmDataArray.forEach((gtmData) => {
            var name = gtmData.getAttribute('data-name') || 'Name not found';
            var productId = gtmData.getAttribute('data-id') || 'Id not found';
            var price = gtmData.getAttribute('data-price') || '-.--';
            var productQuantity = gtmData.getAttribute('data-quantity') || '1';
            var category = gtmData.getAttribute('data-category') || 'Category not found';
            var productGtmData = {
                name: String(name),
                id: String(productId),
                price: String(price),
                quantity: String(productQuantity),
                category: String(category)
            };

            dataProducts.push(productGtmData);
        });

        window.dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        action: 'checkout',
                        step: stepNumber
                    },
                    products: dataProducts
                }
            }
        });
    },

    /**
     * Pushes the purchase event to the dataLayer.
     * @param {Object} productsElements - The elements that contains the product data attributes
     */
    pushPurchase: function (productsElements) {
        var gtmData = productsElements;

        if (!gtmData) {
            return;
        }

        var affiliation = gtmData.getAttribute('data-affiliation');
        var id = gtmData.getAttribute('data-id');
        var revenue = gtmData.getAttribute('data-revenue');
        var shipping = gtmData.getAttribute('data-shipping');
        var tax = gtmData.getAttribute('data-tax');
        var productList = gtmData.getAttribute('data-product-list');
        var productData = JSON.parse(productList);

        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        action: 'purchase',
                        affiliation: affiliation,
                        id: id,
                        revenue: revenue,
                        shipping: shipping,
                        tax: tax
                    },
                    products: productData
                }
            }
        });
    }
};

gtmjs.init();
